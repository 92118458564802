<div [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.current.card + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">

    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme !== 'light'}">
            <app-text [block]="block" [theme]="theme"></app-text>

            <div class="block md:hidden">
                <p-carousel
                    #carousel
                    [showIndicators]="false"
                    [showNavigators]="false"
                    [value]="blockWithoutButtons.blocks"
                    [numVisible]="1"
                    [numScroll]="1"
                    [circular]="false"
                >
                    <ng-template let-tile pTemplate="item">
                        <div [class]="alignment[block.alignment.value]['odd'] + ' ' + 'h-full'">
                            <a [href]="tile.entry ? tile.entry : (tile.url ? tile.url : safeURL )" class="no-underline"
                               [ngClass]="{'cursor-auto': !(tile.entry ? tile.entry : (tile.url ? tile.url : false))}">
                                <div [class]="'h-full border-round ' + theme.fullTheme + ' ' + (theme.theme === 'dark' ? 'p-5 shadow-2' : '')">
                                    <div class="flex align-items-center mb-2">
                                        <app-icon [icon]="tile.icon.value" class="mr-3 text-primary"></app-icon>
                                        <div class="text-primary-400 font-medium line-height-3">{{ tile.subtitle }}</div>
                                    </div>
                                    <div [innerHTML]="tile.title | safeHTML"
                                         class="font-bold line-height-3 text-2xl mb-2 [&>p]:mb-0 [&>p]:mt-0"></div>
                                    <div *ngIf="tile.content_limited" [innerHTML]="tile.content_limited | safeHTML"
                                         class="text-sm line-height-3 [&>p]:mb-0 [&>p]:mt-0"></div>
                                </div>
                            </a>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <div class="flex justify-content-center mt-3">
                            <div (click)="carousel.navBackward($event)"
                                 class="mr-3 h-[40px] w-[40px] bg-brand-color flex justify-content-center align-items-center cursor-pointer rounded-full">
                                <i class="pi pi-chevron-left text-sm"></i>
                            </div>
                            <div (click)="carousel.navForward($event)"
                                 class="mr-3 h-[40px] w-[40px] bg-brand-color flex justify-content-center align-items-center cursor-pointer rounded-full">
                                <i class="pi pi-chevron-right text-sm"></i>
                            </div>
                        </div>
                    </ng-template>
                </p-carousel>
            </div>

            <div class="hidden md:block">
                <div class="grid">
                    <div *ngFor="let tile of blockWithoutButtons.blocks; let odd = odd"
                         [class]="alignment[block.alignment.value][odd ? 'odd' : 'even']">
                        <a [href]="tile.entry ? tile.entry : (tile.url ? tile.url : safeURL)" class="no-underline"
                           [ngClass]="{'cursor-auto': !(tile.entry ? tile.entry : (tile.url ? tile.url : false))}">
                            <div [class]="'h-full border-round ' + theme.fullTheme + ' ' + (theme.theme === 'dark' ? 'p-5 shadow-2' : '')">
                                <div class="flex align-items-center mb-2">
                                    <app-icon [icon]="tile.icon.value" class="mr-3 text-primary"></app-icon>
                                    <div class="text-primary-400 font-medium line-height-3">{{ tile.subtitle }}</div>
                                </div>
                                <div [innerHTML]="tile.title | safeHTML"
                                     class="font-bold line-height-3 text-2xl mb-2 [&>p]:mb-0 [&>p]:mt-0"></div>
                                <div *ngIf="tile.content_limited" [innerHTML]="tile.content_limited | safeHTML"
                                     [class]="'text-sm line-height-3 [&>p]:mb-0 [&>p]:mt-0 ' + (theme.theme === 'light' ? 'text-700 ' : 'text-white')"></div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

