<section [class]="title_alignment[block.title_alignment?.value ?? 'left']">
    <span *ngIf="block.subtitle" [class]="'block mb-1 ' + theme.current.textSubtitle">{{ block.subtitle }}</span>

    <h1 *ngIf="block.title && isHeading; else notHeading"
        [class]="'font-bold mb-3 line-height-3 ' + theme.current.textTitle + ' ' + titleSize" [innerHTML]="block.title | safeHTML"></h1>

    <ng-template #notHeading>
        <div *ngIf="block.title"
             [class]="'font-bold mb-3 line-height-3 [&>a]:text-primary [&>a]:no-underline [&>a]:font-bold ' + theme.current.textTitle + ' ' + titleSize"
             [innerHTML]="block.title | safeHTML"></div>
    </ng-template>

    <div *ngIf="block.content_limited"
         [class]="'mt-0 mb-3 md:mb-4 line-height-3 [&>p]:leading-8 [&>a]:text-primary [&>a]:no-underline [&>a]:font-bold ' + theme.current.content"
         [innerHTML]="block.content_limited | safeHTML"></div>

    <app-button *ngFor="let button of buttons; let last = last;" [theme]="theme" [block]="button"
                [blockClass]="' ' + (buttons.length > 1 ? 'mb-3' : '') + ' ' + ((buttons.length > 1 && !last) ? 'mr-0 md:mr-3' : '')"></app-button>
</section>
