<div [class]="'z-1 relative py-3 px-3 md:!px-16 lg:!px-6 xl:!px-12 2xl:!px-29 ' + (blockClass ? (blockClass + ' ') : '') + theme.current.breadcrumbs + (block.class_overwrite ? (' ' + block.class_overwrite) : '' )">
    <div class="flex align-items-center">
        <a href="/"
           class="text-700 no-underline !underline-offset-4 !decoration-2 !decoration-brand-color hover:underline">
            <app-icon icon="pi-home" class=""></app-icon>
        </a><span>&nbsp;/&nbsp;</span>

        <ng-container *ngFor="let crumb of ancestors; let last = last">
            <a [href]="crumb.url"
               class="no-underline !underline-offset-4 !decoration-2 !decoration-brand-color hover:underline"
               [ngClass]="{'text-700': !last, 'text-900 font-semibold': last}">{{ crumb.title }}</a><span *ngIf="!last">&nbsp;/&nbsp;</span>
        </ng-container>
    </div>
</div>
