import {AfterViewInit, Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {Block}                                                      from "../../block";
import {Theme}                                                      from "../../../models/util";

@Component({
    selector   : 'app-newsletter',
    styleUrl   : 'newsletter.component.scss',
    templateUrl: './newsletter.component.html',
})
export class NewsletterComponent extends Block implements AfterViewInit {
    @ViewChild('scriptSection') scriptSection!: ElementRef;
    textTheme = new Theme('light');

    constructor(
        private _renderer2: Renderer2,
    ) {
        super();
    }

    ngAfterViewInit() {
        const foundScript: any[] = this.scriptSection.nativeElement.getElementsByTagName('script');
        if (foundScript.length === 0) {
            let script  = this._renderer2.createElement('script');
            script.text = `
                hbspt.forms.create({
                    region: "eu1",
                    portalId: "26198844",
                    formId: "${this.block.text}"
                });
            `;

            this._renderer2.appendChild(this.scriptSection.nativeElement, script);
        }
    }
}
